<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="accounts"
              :show-labels="false"
              :load-dictionaries="loadDictionaries"
              :districts="filteringPanel.districts"
              :filters="filteringPanel.filters"
              :loaded="filteringPanel.loaded"
              @search="getData"
              @change="filterData"
              @async-search="onAsyncSearch"
              @dict-loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <b-row v-show="dataTable.visible">
          <b-col>
            <accounts-table
              ref="accounts-table"
              :selectable="selectable"
              @row-select="onRowSelect"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

import AccountsTable from "@/views/RelationshipManagement/AccountsTable";

export default {
  name: "Accounts",
  props: {
    selectable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    FilteringPanel,
    AccountsTable
  },
  data: function() {
    return {
      filteringPanel: {
        selected: {},
        districts: [],
        loaded: false,
        filters: [
          {
            type: "select",
            dataType: "string",
            title: "Account",
            tooltip: "Account name",
            name: "account",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true,
            async: true,
            loading: false,
            startsWith: false
          },
          {
            type: "select",
            dataType: "string",
            tooltip: "Responsible user name",
            title: "Assigned To",
            name: "assigned_to",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            tooltip: "Category name",
            title: "Category",
            name: "category",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "territory",
            title: "Territory",
            tooltip: "Account billing territory",
            name: "territory",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            tooltip: "Country name",
            title: "Country",
            name: "country",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            tooltip: "State name",
            title: "State",
            name: "state",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            tooltip: "City name",
            title: "City",
            name: "city",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            tooltip: "Global",
            title: "Global",
            name: "visible_global",
            trackby: "id",
            label: "label",
            options: this.$form.yesNo.options,
            selected: {},
            multiple: true
          }
        ]
      },
      dataTable: {
        visible: true
      }
    };
  },
  computed: {},
  created() {
    //   console.log('accounts created')
  },

  mounted() {
    //  console.log('accounts mounted')
  },
  methods: {
    onAsyncSearch(payload) {
      let self = this;

      if (payload.filterName === "account") {
        let accountsFilter;

        if (payload.exbSelectRef) {
          accountsFilter = payload.exbSelectRef;
        } else {
          accountsFilter = this.filteringPanel.filters.find(
            f => f.name === "account"
          );
        }

        accountsFilter.loading = true;

        this.$api
          .post("dictionaries/accounts", {
            query: payload.query,
            starts_with: payload.startsWith
          })
          .then(response => {
            accountsFilter.loading = false;

            accountsFilter.options = response.map(u => ({
              id: u.id,
              label: u.name
            }));

            if (payload.exbSelectRef) {
              self.$refs.filteringPanel.setExbSelectOptions({
                filterName: payload.filterName,
                options: response.map(u => ({
                  id: u.id,
                  label: u.name
                }))
              });
            }
          });
      }
    },
    async loadDictionaries() {
      let self = this;

      const districts = async () => {
        let response = await this.$api.post("dictionaries/countries/mapped", {
          ignore_restrictions: false
        });

        self.districts = response;

        self.filteringPanel.districts = self.districts;

        self.filteringPanel.filters.find(
          f => f.name === "country"
        ).options = self.$helpers.getDistinctArray(
          response,
          "country",
          "country",
          "id",
          "label"
        );
      };
      const territories = async () => {
        let response = await this.$api.get("dictionaries/territories");

        self.filteringPanel.filters.find(
          f => f.name === "territory"
        ).options = response.map(u => ({
          id: u.ID,
          label: u.Name
        }));
      };

      const users = async () => {
        let response = await this.$api.get("users");

        self.filteringPanel.filters.find(
          f => f.name === "assigned_to"
        ).options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }));
      };

      const categories = async () => {
        let response = await this.$api.get("dictionaries/accounts/categories");

        self.filteringPanel.filters.find(
          f => f.name === "category"
        ).options = response.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      await Promise.all([
        districts(),
        categories(),
        territories(),
        users()
      ]).then(() => {
        self.filteringPanel.loaded = true;
      });
    },
    onFilteringPanelStateChange() {
      this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible;
    },
    onFilteringPanelLoad() {
      //if (this.$refs.filteringPanel.panelState.isCustomFiltersBar) this.getData();
      this.getData();
    },
    onCustomFilterSelected() {
      this.getData();
    },

    filterData: function(e) {
      //let self = this;

      this.filteringPanel.selected = e;
    },
    getData() {
      //let self = this;

      let fp = this.$refs.filteringPanel;

      if (!fp.isSearchAllowed()) return;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      let f = fp.selected;

      let payload = {
        territories: f.territory ? f.territory : [],
        countries: f.country ? f.country.map(i => i.label) : [],
        states: f.state ? f.state.map(i => i.label) : [],
        cities: f.city ? f.city.map(i => i.label) : [],
        assigned_to: f.assigned_to ? f.assigned_to.map(i => i.id) : [],
        visible_global: f.visible_global
          ? f.visible_global.map(i => i.label)
          : [],
        accounts: f.account ? f.account.map(i => i.label) : [],
        categories: f.category ? f.category.map(i => i.label) : []
      };

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f["custom-filter"])
        );
      }

      this.$refs["accounts-table"].getData(payload);
    },
    getSelectedAccounts() {
      return this.$refs["accounts-table"].getSelectedRows();
    },
    onRowSelect(e) {
      this.$emit("row-select", e);
    }
  },
  watch: {
    "filteringPanel.selected.country": function(newVal) {
      if (!newVal) return;

      this.filteringPanel.filters.find(
        f => f.name === "state"
      ).options = this.$helpers.getDistinctArray(
        this.districts.filter(s =>
          newVal.map(l => l.label).includes(s.country)
        ),
        "state",
        "state",
        "id",
        "label"
      );
    },
    "filteringPanel.selected.state": function(newVal) {
      if (!newVal) return;
      this.filteringPanel.filters.find(
        f => f.name === "city"
      ).options = this.$helpers.getDistinctArray(
        this.districts.filter(s => newVal.map(l => l.label).includes(s.state)),
        "city",
        "city",
        "id",
        "label"
      );
    }
  }
};
</script>

<style>
.fa-toolbar {
  color: #0000008a;
}
</style>
